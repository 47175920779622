import styled from 'styled-components'

import { media } from 'styles/theme/mediaQueries'
import { AnalyzeBreadcrumbsWrapper } from 'components/AnalyzeV2/AnalyzeBreadcrumbs/analyzeBreadcrumbs.styles'

export const Container = styled.div<{ hasCustomSpacing: boolean }>`
    grid-area: topbar;
    box-sizing: border-box;
    width: calc(100% - 256px);
    min-height: 86px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    position: absolute;
    z-index: 110;
    background-color: white;
    border-bottom: 1px solid ${({ theme }) => theme.colors.primaryGray2};
    position: relative;
    width: auto;
    margin-left: 70px;

    ${media.lg} {
        padding-left: ${({ hasCustomSpacing }) => {
            if (!hasCustomSpacing) {
                return '48px'
            }
        }};
        padding-right: 48px;
    }

    .dds-topbar-menu {
        margin-left: auto;

        ${media.md} {
            margin-left: 60px;
        }

        &:only-child {
            margin-left: auto;
        }
    }
`

export const SurveySelectBox = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    ${media.md} {
        margin-right: auto;

        &:has(+ ${AnalyzeBreadcrumbsWrapper}) {
            margin-right: 60px;
        }
    }
`

export const Switch = styled.div`
    display: flex;
    gap: 60px;

    ${media.md} {
        &:first-child {
            margin-left: auto;
        }
    }
`
