import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
  }

  body {
    .dds-onboarding-tooltip {
      z-index: 110;
    }

    .dds-single-select__popper {
      z-index: 1000;
    }

    .dds-nps-card-breakdown,
    .dds-inclusion-score-analysis-card-breakdown {
      .MuiDialog-scrollPaper {
        align-items: flex-start;
      }

      .MuiDialog-paper {
        margin-left: 70px;
        margin-top: 110px;
      }
    }
  }

  div[id*="react-joyride-step-"] {
    .__floater {
      transition: opacity 0.3s ease 0s, transform 0s !important;
    }
  }

  .lumi-navbar div[class*="-icon"] {
    background-color: unset !important;
  }

  
`
