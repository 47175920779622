import React from 'react'
import { ChartLegendItem, colors, NpsCard, NpsCardSkeleton, OrganizationIcon } from '@diversioteam/diversio-ds'
import mixpanel from 'mixpanel-browser'

import { useGetNps } from 'hooks/useGetNps'
import { useSurveys } from 'hooks/useSurveys/useSurveys'
import { useTooltips } from 'hooks/tooltips/useTooltips'
import { TooltipId } from 'utils/tooltips.enum'
import TAXONOMIES from 'utils/taxonomies'

import * as S from './nps.styles'

export const Nps = () => {
    const { data, isLoading } = useGetNps()
    const { survey } = useSurveys()
    const { getOnboardingButtonProps } = useTooltips()

    const onboardingButtonProps = getOnboardingButtonProps(TooltipId.HomeNps)

    const hasBenchmark =
        data?.data.find(
            (dataItem) => dataItem.survey.year === survey?.year && dataItem.survey.quarter === survey.quarter,
        )?.benchmark.value !== null

    const customOnboardingButtonProps = {
        ...onboardingButtonProps,
        steps: [
            ...(onboardingButtonProps?.steps || []),
            {
                title: 'NPS Legend',
                description: (
                    <>
                        <ChartLegendItem
                            label="Needs Improvement"
                            marker={{
                                enable: true,
                                color: colors.lobster50,
                            }}
                        />

                        <ChartLegendItem
                            label="Good"
                            marker={{
                                enable: true,
                                color: colors.tangerineSharp,
                            }}
                        />

                        <ChartLegendItem
                            label="Excellent"
                            marker={{
                                enable: true,
                                color: colors.green50,
                            }}
                        />

                        {hasBenchmark && (
                            <S.BenchmarkLegendRow>
                                <OrganizationIcon type="line" width={14} height={14} color={colors.secondary500} />

                                <ChartLegendItem label="Industry Benchmark" />
                            </S.BenchmarkLegendRow>
                        )}
                    </>
                ),
            },
        ],
    }

    if (isLoading || !survey) {
        return <NpsCardSkeleton data-testId="nps-skeleton" />
    }

    if (data?.data.length && survey) {
        return (
            <NpsCard
                results={data.data}
                selectedSurvey={{
                    year: survey.year,
                    quarter: survey.quarter,
                    yearQuarterLabel: survey.name,
                }}
                onTrigger={(trigger) => mixpanel.track(TAXONOMIES.HOME_INTERACT_NPS_SCORE, { trigger })}
                onTabChange={(tab) => mixpanel.track(TAXONOMIES.HOME_SWITCH_NPS_VIEW, { tab })}
                onSurveySwitch={(selectedSurveys) =>
                    mixpanel.track(TAXONOMIES.HOME_SWITCH_NPS_SURVEY, { selectedSurveys })
                }
                onboardingButtonProps={customOnboardingButtonProps}
                data-testId="nps"
            />
        )
    }

    return null
}
